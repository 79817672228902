import { graphql } from 'gatsby'
import React from 'react'
import PageLayout from '../components/PageLayout/PageLayout'
import SubpageHeader from '../components/SubpageHeader/SubpageHeader'
import { getMetaTags } from '../utils/metaTags'
import MainZoneRenderer from '../zones/MainContentZone'

export default function LayoutGeneral({ pageContext, data, location }) {
  const zones = data.strapiGQL.page.contentZones

  return (
    <PageLayout>
      <SubpageHeader
        title={pageContext.title}
        summary={pageContext.subtitle}
        metaTags={getMetaTags(pageContext)}
      />
      {zones && <MainZoneRenderer zones={zones} location={location} />}
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      page(id: $id) {
        ...StrapiGQL_PagesWithContentZones
      }
    }
  }
`
